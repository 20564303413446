import React from "react"
import { Router } from "@reach/router"
import { useIntl, getLanguagePathPrefix } from "../utils"
import { Layout, SEO, Protected } from "../components"
import {
  Sites,
  Site,
  SiteSourcesEdit,
  SiteSourceEdit,
  SiteSource,
  SiteEdit,
  SiteBuildEdit,
  SiteDomains,
} from "../containers"
const Page = () => {
  const intl = useIntl()
  const languagePathPrefix = getLanguagePathPrefix(intl)
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "hello" })} />
      <Router basepath={`${languagePathPrefix}/sites`}>
        <Protected
          path="/:siteId/sources/edit"
          component={SiteSourcesEdit}
        ></Protected>
        <Protected
          path="/:siteId/build/edit"
          component={SiteBuildEdit}
        ></Protected>
        <Protected path="/:siteId/domains" component={SiteDomains}></Protected>
        <Protected path="/:siteId/edit" component={SiteEdit}></Protected>
        <Protected
          path="/:siteId/sources/:sourceId/edit"
          component={SiteSourceEdit}
        ></Protected>
        <Protected
          path="/:siteId/sources/:sourceId"
          component={SiteSource}
        ></Protected>
        <Protected path="/:siteId" component={Site}></Protected>
        <Protected path="/" component={Sites} />
      </Router>
    </Layout>
  )
}
export default Page
